import React from "react"
import { Fallback } from "./components/Fallback"
import { ComponentsContext } from "./ComponentsProvider"

export function ContentItemRenderer({ componentName, data, ...rest }) {
  const components = React.useContext(ComponentsContext)

  const Component = components[componentName] || Fallback

  return <Component data={data} componentName={componentName} {...rest} />
}
